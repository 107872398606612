.c-icon {
  color: var(--c-main);
  font-size: var(--text-md);

  &--large {
    font-size: 1.5rem;
  }

  &--huge {
    font-size: 1.75rem;
  }

  &--bg {
    padding: var(--space-sm);
    border-radius: var(--border-radius);
    background-color: var(--c-light-pink);

    &--evento {
      padding: 0.375rem;
    }
  }

  &--arrow {
    font-size: 1.25rem;
    color: var(--c-gray-500);
  }

  &--blue {
    color: var(--c-additional-light-blue);
  }
}
