@media screen and (max-width: 996px) {
  :root {
    ion-menu {
      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        margin-top: env(safe-area-inset-top);
        margin-bottom: env(safe-area-inset-bottom);
        left: 304px;
        width: 1rem;
        border-radius: 8px 0 0 8px;
        transition: margin ease-in-out 0.3s;

        @media screen and (max-width: 340px) {
          left: 264px;
        }
      }

      &::before {
        top: 2rem;
        bottom: 2rem;
        background: #ffc2d2;
        z-index: 3;
      }

      &::after {
        top: 3rem;
        bottom: 3rem;
        transform: translateX(-1rem);
        background: #ff6a95;
      }
    }
  }
}

.menu-content {
  &-reveal {
    box-shadow: none !important;
  }

  &-open {
    margin: 0 1rem;
    margin-top: calc(1rem + env(safe-area-inset-top));
    margin-bottom: calc(1rem + env(safe-area-inset-bottom));
    border-radius: 8px;
  }
}

.c-sidemenu {
  --icon-color: rgba(255, 255, 255, 0.7);

  ion-menu.show-menu {
    background: var(--c-primary-pink);

    ion-content {
      --background: var(--c-primary-pink) url('/assets/img/backgrounds/forms.png') 125% no-repeat;
    }
  }

  &__section {
    margin-right: 2rem;
    margin-bottom: 0;
    padding-bottom: var(--space-xs);
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
    background: transparent;

    &:first-child {
      margin-top: env(safe-area-inset-top);
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__icon {
    color: var(--icon-color);
    font-size: 1.25rem;
  }

  &__row {
    margin: var(--space-sm) var(--space-xs) 0;
    padding: var(--space-sm) var(--space-xs);

    border-radius: 3px;
    color: var(--c-white);
    font-size: var(--text-regular);
    font-weight: 500;
    letter-spacing: 0.22px;

    &--active {
      background-color: var(--c-light-pink);
      color: var(--c-main);
      --icon-color: var(--c-main);
    }
  }

  &__username {
    color: var(--c-white);
  }

  &__select {
    .c-field__select {
      color: var(--c-white);
    }
  }
}
