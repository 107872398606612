.c-slides {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  background: var(--c-white);

  &__wrapper {
    width: 100%;
    min-height: 100%;
    --bullet-background: var(--c-black);
    --bullet-background-active: var(--c-main);
  }

  &__slide {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-top: var(--space-md);
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }

  &__title {
    margin: var(--space-sm) 0 var(--space-regular);
    color: var(--c-black);
    font-size: var(--text-xl);
    font-weight: 500;
  }

  &__description {
    color: var(--c-gray-600);
    font-size: var(--text-md);
  }

  &__image {
    max-height: 14rem;
    margin-bottom: var(--space-md);

    @media (max-width: 320px) {
      max-height: 10rem;
    }

    img {
      height: 14rem;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    padding: 0 var(--space-md);
  }

  &__btn-hide {
    opacity: 0;
  }

  &--page {
    height: 80vh;

    .c-slides__image {
      max-height: unset;
    }
  }
}

.swiper-pagination-bullets {
  display: none;
}
