:root {
  ion-popover::part(content) {
    top: 2.6rem !important;
    left: unset !important;
    right: 1.5rem !important;
    transform-origin: unset !important;

    padding: var(--space-regular) !important;
    width: unset !important;
    border-radius: 6px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  }

  ion-popover::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }

  .popover-content--card-cardapio {
    &::part(content) {
      top: 8.75rem !important;
      right: 3.75rem !important;
    }
  }

  .grade-horaria__popover {
    &::part(content) {
      padding: 0 !important;
      top: 50% !important;
      transform: translateX(8px) translateY(-50%) !important;
      width: calc(100vw - 32px) !important;
    }
  }
}
