ion-segment {
  --background: var(--c-gray-300) !important;
}

ion-segment-button {
  --border-color: var(--c-gray-300);
  --border-width: 2px;
  --color: var(--c-gray-500) !important;
  --color-checked: var(--c-gray-700) !important;
  --padding-top: var(--space-xs);
  --padding-bottom: var(--space-xs);
  --padding-start: var(--space-md);
  --padding-end: var(--space-md);

  width: 33.3% !important;
  font-size: var(--text-regular);
  font-weight: 500 !important;
  overflow: hidden;

  &:first-of-type.segment-button-checked {
    box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.08) !important;
  }

  &:last-of-type.segment-button-checked {
    box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.08) !important;
  }
}
