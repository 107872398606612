.c-empty-content {
  position: absolute;
  width: 80%;
  max-width: 25rem;
  height: 40vh;
  max-height: 100%;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;

  transform: translateY(-50%);

  display: flex;
  flex-flow: column;
}
