:root {
  --space-top: calc(100vh - 620px);
}

ion-modal::part(content) {
  --width: calc(100% - 2 * var(--space-xs));
  --height: calc(100% - var(--space-top));
  --max-height: 84vh;
  --border-radius: 35px;
  margin-top: auto;
  margin-bottom: calc(var(--space-xs) + env(safe-area-inset-bottom));
  z-index: 101;
}

ion-modal::part(backdrop) {
  z-index: 100;
  --backdrop-opacity: 0.32;
}

.c-ion-modal {
  &--full::part(content) {
    --width: 100vw;
    --height: 100vh;
    --max-height: 100vh;
    --border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 101;
  }

  &--med {
    --space-top: calc(100vh - 440px);
  }

  &--instagram::part(content) {
    --width: unset;
    --height: 100vh;
    --max-height: 100vh;
    margin-top: 0;

    background: transparent;
  }

  &--short {
    --space-top: calc(100vh - 386px);
  }

  &--square {
    --border-radius: 0;
  }

  &--auto {
    &::part(content) {
      --height: auto !important;
      --max-height: auto !important;
    }

    .ion-page {
      position: relative;
      contain: content;
    }
  }
}

.c-modal {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: var(--space-md);
  border-radius: 35px;
  background: white;
  overflow: auto;
  position: relative;

  &__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 999;

    padding: 0.2rem;
    background: var(--c-gray-100);
    border-radius: 50%;
    font-size: 1.5rem;
  }

  &__icon {
    margin-bottom: var(--space-xs);
    min-height: 2.75rem;
    color: var(--c-main);
    font-size: 2.75rem;
  }

  &__title {
    font-size: var(--text-xl);
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.25px;
    text-align: center;
  }

  &__img {
    height: 12rem;
    width: 12rem;
  }

  &__description {
    margin-top: var(--space-regular);
    color: var(--c-gray-600);
    font-size: var(--text-md);
    line-height: 1.1875rem;
    letter-spacing: 0.51px;
    text-align: center;
  }

  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: auto;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 1rem;
      width: 100%;
      height: 1px;
      background: var(--color-gray-400);
    }
  }

  &--center {
    align-items: center;
  }

  &--paddingless {
    padding: var(--space-lg) 0 var(--space-md);
  }

  &--square {
    border-radius: 0;
  }
}
