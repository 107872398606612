.c-empty-state {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;

  &__image {
    max-height: 13rem;

    &--big {
      max-height: 15rem;
      height: 15rem;
    }
  }

  &__label {
    margin: var(--space-lg) 0;
    max-width: 21rem;
    color: var(--c-gray-600);
    font-size: var(--text-regular);
    font-weight: normal;
    line-height: 1.3125rem;
    letter-spacing: 0.49px;
    text-align: center;

    &--multiple {
      margin: var(--space-xs) 0;
    }
  }
}
