.c-button {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  --color: var(--c-main);
  margin: 0;

  border: 1px solid var(--c-main);
  border-radius: 50px;

  color: var(--c-main);
  font-size: var(--text-sm);
  font-weight: bold;
  letter-spacing: 0.65px;

  &__spinner {
    --color: var(--c-gray-600);

    &--white {
      --color: var(--c-white);
    }
  }

  &--link {
    --padding-start: 0rem;
    --padding-end: 0rem;
    --padding-top: 0rem;
    --padding-bottom: 0rem;

    min-height: unset;
    border: none;

    text-decoration: underline;
    text-transform: none;
    font-size: var(--text-xs);
    font-weight: normal;
  }

  &--clear {
    border: none;
  }

  &--white {
    border: none;
    background-color: var(--c-white);
  }

  &--reverse {
    border: none;
    color: var(--c-white);
    background-color: var(--c-main);
  }

  &--main {
    width: 100%;
    height: 40px;
    border-radius: var(--space-xs);
  }

  &--faded,
  &[disabled] {
    filter: brightness(1.75);
  }

  &[disabled] {
    background-color: var(--c-gray-600);
  }
}
