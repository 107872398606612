.c-field {
  &__item-wrapper {
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0px;
    --inner-padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --min-height: var(--space-regular);
    --background-activated: transparent;
    --background: transparent;
    --border-color: transparent;
    --color: var(--c-black);

    &--reverse {
      --color: var(--c-white);
    }
  }

  &__border {
    border: 1px solid var(--c-gray-300);
    border-radius: 4px;
    background-color: var(--c-white);

    &--focused {
      border: solid 1px var(--c-main);
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  &__label,
  &__inline-label {
    color: var(--c-black);
    font-size: var(--text-md);
    font-weight: 500;
    margin: 0;

    &--inverse {
      color: var(--c-white);
      font-weight: normal;
    }

    &--light {
      margin-left: var(--space-xs) !important;
      font-weight: 400;
      font-size: var(--text-regular) !important;
    }
  }

  &__label {
    margin-bottom: var(--space-xs);
  }

  &__left-icon {
    margin-left: var(--space-regular);
    color: var(--c-gray-600);
    font-size: var(--text-xl);
  }

  &__right-icon {
    margin-right: var(--space-regular);
    color: var(--c-gray-600);
    font-size: var(--text-xl);
  }

  &__input,
  &__textarea,
  &__select,
  &__datetime {
    height: 50px;
    color: var(--c-black);
    font-size: var(--text-md);
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.28px;
    --padding-start: 1rem;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
    --padding-end: 1rem;
    --border-radius: 0px;
  }

  &__select {
    display: flex;
    align-items: center;
    font-size: var(--text-md) !important;

    &--clear {
      --padding-top: var(--space-xxs);
      --padding-right: var(--space-xs);
      color: var(--c-gray-600);

      .selected-text {
        min-width: unset;
      }
    }
  }

  &__textarea {
    min-height: 8rem;
    --padding-start: var(--space-sm);
    --padding-end: var(--space-sm);
    --placeholder-font-weight: normal;

    textarea {
      min-height: 8rem !important;
    }
  }

  &__checkbox {
    margin: 0 !important;
    --size: var(--space-regular);
    --background: transparent;
    --border-color-checked: var(--c-main);
    --background-checked: var(--c-main);

    &--disabled {
      --border-color-checked: var(--c-gray-500);
      --background-checked: var(--c-gray-500);
    }

    &--inverse {
      --background-checked: var(--c-white);
      --border-color: var(--c-white);
      --border-color-checked: var(--c-white);
      --border-width: 2px;
      --checkmark-color: var(--c-main);
    }
  }

  &--short {
    max-width: 10rem;
  }

  &--clear {
    .c-field__border {
      background-color: unset;
      border: unset;
    }

    .c-field__input,
    .c-field__textarea,
    .c-field__datetime,
    .c-field__select {
      height: auto;
      color: var(--c-gray-600);
      font-size: var(--text-sm) !important;
      font-weight: normal;
      letter-spacing: 0.22px;
      --padding-start: 0;
      --padding-top: 0rem;
      --padding-bottom: 0rem;
      --padding-end: 0rem;
    }
  }

  &--disabled {
    border: unset;
    background-color: var(--c-gray-100);
  }
}
