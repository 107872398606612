$primary-font: 'Roboto', sans-serif;
$heading-font-weight: 14px;
$font-path: '/assets/fonts';

body {
  font-family: $primary-font;
  color: var(--c-black);
  line-height: var(--heading-line-height);
}

h1,
h2,
h3,
h4,
h5,
h6,
form legend {
  margin: 0;
  line-height: var(--heading-line-height);
}

/* text size */
h1 {
  font-size: var(--text-xxl);
}

h2 {
  font-size: var(--text-lg);
}

h3 {
  font-weight: bold;
  font-size: var(--text-md);
}

h4 {
  font-weight: 500;
  font-size: var(--text-md);
}

h5 {
  font-weight: 500;
  color: var(--c-gray-500);
  font-size: var(--text-sm);
}

p {
  margin: 0;
  line-height: var(--body-line-height);
  font-size: var(--text-sm);
}

// New Font-face
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/roboto/roboto-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('#{$font-path}/roboto/roboto-bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/roboto/roboto-medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/roboto/roboto-light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: url('#{$font-path}/roboto/roboto-italic.woff') format('woff');
}
