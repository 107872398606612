.c-date {
  &__day {
    color: var(--date-color, var(--c-main));
    font-size: var(--text-md);
    font-weight: 500;
    letter-spacing: var(--letter-spacing-sm);
  }

  &__month {
    color: var(--c-gray-600);
    font-size: var(--text-xs);
    font-weight: 600;
    letter-spacing: var(--letter-spacing-xl);
    text-transform: uppercase;
  }

  &--column {
    display: flex;
    flex-direction: column;
    align-items: center;

    .c-date__day {
      line-height: 1.25rem;
    }

    .c-date__month {
      color: var(--c-gray-700);
      font-size: var(--text-xs);
      font-weight: 400;
      letter-spacing: var(--letter-spacing-l);
    }
  }
}
