.c-table {
  &__label,
  &__text {
    flex: 1;
    color: var(--c-gray-700);
    font-size: var(--text-md);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }

  &__text {
    letter-spacing: 0.16px;
  }

  &__value {
    flex: 1;
    color: var(--c-gray-700);
    font-size: var(--text-md);
    font-weight: normal;
    text-align: left;
  }

  &__row {
    display: flex;

    --border-spacing: var(--space-regular);
    --border-padding: calc(var(--space-regular) + 34px);

    &:not(:last-of-type) {
      position: relative;
      margin-bottom: var(--border-spacing);
      padding-bottom: var(--border-spacing);

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: var(--border-padding);
        width: calc(100% - var(--border-padding));
        border-bottom: 1px solid var(--c-gray-300);
      }
    }

    &--full {
      --border-padding: 0px;
    }

    &--large {
      --border-padding: calc(var(--space-regular) + 40px);
    }

    &--small {
      --border-spacing: var(--space-sm);
      --border-padding: 0rem;
    }
  }
}
