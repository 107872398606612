.l-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  min-height: 100%;
  overflow: auto;
  background-color: var(--c-gray-100);

  &--center {
    align-items: center;
  }

  &--white {
    background-color: var(--c-white);
  }

  &--pink {
    background-color: var(--c-main);
  }

  &--filterDown {
    max-height: 80vh;
  }
}
