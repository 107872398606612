.c-filter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 0vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--c-main);
  background-size: 150%;
  background-position: -6.5rem 3rem;
  background-repeat: repeat-y;
  z-index: 999;
  transition: var(--default-animation);
  overflow: hidden;

  &__content {
    margin-bottom: var(--space-regular);
    flex: 1;
    padding: 0 1rem;
    overflow: auto;
  }

  &__divider {
    height: 3px;
    width: 100%;
    background-color: black;
    border-radius: var(--border-radius);
    opacity: 0.15;
  }

  &__bottom {
    padding: var(--space-md);
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background-color: var(--c-white);
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  &__sublist {
    margin-left: var(--space-xs) !important;
    padding-left: var(--space-md) !important;
    border-left: 3px solid rgba(33, 33, 33, 0.2);
  }

  &--expanded {
    max-height: 100vh;
    overscroll-behavior-y: contain;
  }
}
