/**
  Colors and variables for Uniapp 2.0
**/

:root {
  // Available on Zeplin Styleguide
  --c-white: #ffffff;
  --c-gray-50: #fafafa;
  --c-gray-100: #f5f5f5;
  --c-gray-200: #eeeeee;
  --c-gray-300: #e0e0e0;
  --c-gray-400: #bdbdbd;
  --c-main-gray-40: #999999;
  --c-gray-500: #9e9e9e;
  --c-gray-600: #757575;
  --c-gray-700: #424242;
  --c-gray-800: #424242;
  --c-black: #212121;
  --c-primary-orange: #fe9e38;
  --c-primary-pink: #ff226d;
  --c-primary-blue: #009aff;
  --c-primary-teal: #2ac6f1;
  --c-light-orange: #fff7ed;
  --c-light-pink: #ffebf2;
  --c-light-blue: #e9f5ff;
  --c-light-teal: #ecfaff;
  --c-additional-red: #fe3f38;
  --c-additional-green: #5eb13b;
  --c-additional-yellow: #ffd500;
  --c-additional-gold: #ffbb00;
  --c-additional-light-red: #ffeeed;
  --c-additional-light-blue: #bfd1df;
  --c-additional-light-green: #f1f9ee;
  --c-additional-light-yellow: #fffce5;
  --c-additional-light-gray: #cccccc;
  --c-instagram-red: #ed4956;
  --c-orangey-red: #ff4036;

  // accents and background
  --c-accent-1: #a133b4;
  --c-accent-2: #009688;
  --c-accent-3: #00bcd4;
  --c-accent-4: #283593;
  --c-accent-5: #e91e63;
  --c-accent-6: #ff5722;
  --c-accent-7: #795548;
  --c-accent-8: #607d8b;
  --c-accent-9: #33691e;
  --c-accent-10: #757575;
  --c-accent-11: #fbc02d;
  --c-accent-12: #f57c00;

  --c-background-1: #f3e5f5;
  --c-background-2: #e0f2f1;
  --c-background-3: #e0f7fa;
  --c-background-4: #e8eaf6;
  --c-background-5: #fce4ec;
  --c-background-6: #fbe9e7;
  --c-background-7: #efebe9;
  --c-background-8: #eceff1;
  --c-background-9: #dcedc8;
  --c-background-10: #fafafa;
  --c-background-11: #fffde7;
  --c-background-12: #fff3e0;

  // Gradients
  --c-grad-orange-to-pink: linear-gradient(196deg, var(--c-light-orange), var(--c-pink));
  --c-grad-pink-to-blue: linear-gradient(196deg, var(--c-pink), var(--c-blue));
  --c-grad-yellow-to-blue: linear-gradient(196deg, var(--c-light-orange), var(--c-blue));
  --c-grad-all: linear-gradient(190deg, var(--c-light-orange), var(--c-pink) 50%, var(--c-blue));

  // Main colors
  --c-main: var(--c-primary-pink);
  --c-light-main: var(--c-light-pink);

  // Altering ionic theming
  --ion-color-primary: var(--c-main);

  // box-shadow
  --box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
}

$colors: (
  'white': var(--c-white),
  'red': var(--c-additional-red),
  'green': var(--c-additional-green),
  'blue': var(--c-primary-blue),
  'pink': var(--c-primary-pink),
  'gray--100': var(--c-gray-100),
  'gray--200': var(--c-gray-200),
  'gray--300': var(--c-gray-300),
  'gray--400': var(--c-gray-400),
  'gray--500': var(--c-gray-500),
  'gray--600': var(--c-gray-600),
  'gray--700': var(--c-gray-700),
  'gray--800': var(--c-gray-800),
  'black': var(--c-black),
);

@each $color, $value in $colors {
  .u-#{$color} {
    color: $value !important;
  }

  .u-bg-#{$color} {
    background-color: $value !important;
  }
}
