.c-avatar {
  --avatar-size: 2rem;
  height: var(--avatar-size);
  width: var(--avatar-size);
  min-height: var(--avatar-size);
  min-width: var(--avatar-size);

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  background-color: var(--c-gray-400);
  border: solid 1.4px var(--c-white);
  border-radius: 50%;

  color: var(--c-gray-600);
  font-size: var(--text-xs);

  &--tiny {
    --avatar-size: 1rem;
  }

  &--small {
    --avatar-size: 1.5rem;
  }

  &--large {
    --avatar-size: 3rem;
  }
}
