ion-fab-button {
  margin-bottom: var(--space-xs);
  margin-right: var(--space-xs);

  &[data-desc] {
    position: relative;

    &::after {
      position: absolute;
      content: attr(data-desc);
      z-index: 1;
      right: 4rem;
      bottom: 0.25rem;
      padding: var(--space-xxs) var(--space-xs);
      border-radius: 3px;
      background-color: var(--c-white);
      box-shadow: var(--box-shadow);
      color: var(--c-gray-700);
      font-size: var(--text-sm);
      letter-spacing: 0.18px;
    }
  }
}

.c-fab {
  --close-icon-font-size: 32px;

  &.ion-activated {
    background-color: var(--c-white);
    border-radius: 50%;
  }

  &--white {
    --background: var(--c-white);
    --color: var(--c-main);
  }

  &--small {
    --background: var(--c-white);
    --box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    --color: var(--c-gray-700);
    margin-bottom: var(--space-sm);

    ion-icon {
      font-size: 1.25rem;
    }
  }
}

.fab-list-active {
  padding-left: var(--space-xs);
}

.c-fab-evento {
  position: fixed;
}
