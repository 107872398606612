.c-badge {
  display: inline-block;
  padding: var(--space-xxs) var(--space-xs);
  border-radius: 6px;
  color: var(--c-white);
  font-size: var(--text-sm);
  font-weight: 600;
  letter-spacing: var(--letter-spacing);
  text-transform: uppercase;
  background-color: var(--c-primary-orange);

  $colors: (
    'green' var(--c-additional-green) var(--c-additional-light-green),
    'blue' var(--c-primary-blue) var(--c-light-blue),
    'red' var(--c-additional-red) var(--c-additional-light-red),
    'gray' var(--c-gray-300) var(--c-accent-10),
    'accent-1' var(--c-accent-1) var(--c-background-1),
    'accent-2' var(--c-accent-2) var(--c-background-2),
    'accent-3' var(--c-accent-3) var(--c-background-3),
    'accent-4' var(--c-accent-4) var(--c-background-4),
    'accent-5' var(--c-accent-5) var(--c-background-5),
    'accent-6' var(--c-accent-6) var(--c-background-6),
    'accent-7' var(--c-accent-7) var(--c-background-7),
    'accent-8' var(--c-accent-8) var(--c-background-8),
    'accent-9' var(--c-accent-9) var(--c-background-9),
    'accent-10' var(--c-accent-10) var(--c-background-10),
    'accent-11' var(--c-accent-11) var(--c-background-11),
    'accent-12' var(--c-accent-12) var(--c-background-12)
  );

  @each $color in $colors {
    &--#{nth($color, 1)} {
      background-color: #{nth($color, 2)};
      color: #{nth($color, 3)};
    }
  }
}
