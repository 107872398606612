.c-loading-state {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 116px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    max-width: 90px;
    height: auto;
    object-fit: contain;

    animation-name: bounce;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-0.75rem);
      }
    }
  }

  &__sparks {
    position: absolute;
    top: calc(50% - 6.25rem);
    right: calc(50% - 3.5rem);
    font-size: var(--text-sm);
  }

  &__yellow {
    margin-right: var(--space-xs);
    color: var(--c-additional-gold);

    animation-name: fade-yellow;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes fade-yellow {
      0%,
      45%,
      100% {
        opacity: 0%;
      }

      50% {
        opacity: 100%;
      }
    }
  }

  &__purple {
    margin-right: var(--space-xs);
    font-size: var(--text-regular);
    color: var(--c-accent-1);
    font-weight: 500;

    animation-name: fade-purple;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes fade-purple {
      0%,
      60%,
      100% {
        opacity: 0%;
      }

      65% {
        opacity: 100%;
      }
    }
  }

  &__teal {
    margin-top: calc(1 * var(--space-xxs));
    color: var(--c-primary-teal);

    animation-name: fade-teal;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes fade-teal {
      0%,
      60%,
      100% {
        opacity: 0%;
      }

      65% {
        opacity: 100%;
      }
    }
  }

  &__green {
    margin-right: var(--space-sm);
    font-size: var(--text-regular);
    color: var(--c-additional-green);
    font-weight: 500;

    animation-name: fade-green;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes fade-green {
      0%,
      55%,
      100% {
        opacity: 0%;
      }

      60% {
        opacity: 100%;
      }
    }
  }

  &__red {
    margin-top: var(--space-xs);
    margin-right: var(--space-xs);
    color: var(--c-main);

    animation-name: fade-red;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes fade-red {
      0%,
      55%,
      100% {
        opacity: 0%;
      }

      60% {
        opacity: 100%;
      }
    }
  }

  &__blue {
    margin-top: calc(0.5 * var(--space-xxs));
    font-size: var(--text-regular);
    color: var(--c-primary-blue);
    font-weight: 500;

    animation-name: fade-blue;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes fade-blue {
      0%,
      65%,
      100% {
        opacity: 0%;
      }

      75% {
        opacity: 100%;
      }
    }
  }

  &::after {
    content: '';
    height: 100vh;
    width: 100vw;
    background-image: url('/assets/img/backgrounds/forms-alternate.png');
    background-size: 110%;
    background-position: -25% 25%;
    opacity: 0.3;
    top: -1rem;
    left: -1rem;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }
}
