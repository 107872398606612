// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~swiper/css';
@import '~@ionic/angular/css/ionic-swiper';

@import 'theme/animations';
@import 'theme/components.scss';
@import 'theme/layout.scss';
@import 'theme/palette.scss';
@import 'theme/typography.scss';
@import 'theme/utilities.scss';
