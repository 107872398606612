.c-toolbar {
  --background: var(--c-main);
  --padding-start: 0rem;
  --padding-end: 0rem;
  --padding-top: 0.75rem;
  --padding-bottom: 0.75rem;

  /* Our css variables */
  --title-color: var(--c-white);
  --toolbar-icons-color: var(--c-white);
  --toolbar-icons-size: 1.75rem;

  &__title {
    color: var(--title-color);
    font-size: var(--text-xl);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.3px;
  }

  &__icon {
    color: var(--toolbar-icons-color);
    font-size: var(--toolbar-icons-size);
    margin: 0 1rem;

    &--pink {
      --toolbar-icons-color: var(--c-primary-pink);
    }
  }

  ion-back-button,
  ion-menu-button {
    --icon-font-size: var(--toolbar-icons-size);
    --color: var(--toolbar-icons-color);
  }

  ion-back-button {
    --icon-margin-start: 1rem;
    --icon-margin-end: 1rem;
  }

  ion-menu-button {
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  &--gray {
    --background: var(--c-gray-100);
    --title-color: var(--c-gray-700);
    --toolbar-icons-color: var(--c-gray-700);
    box-shadow: unset;
  }

  &--white {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    --background: var(--c-white);
    --title-color: var(--c-gray-700);
    --toolbar-icons-color: var(--c-gray-700);
  }

  &--clear {
    --background: transparent;
    --title-color: var(--c-gray-700);
    --toolbar-icons-color: var(--c-gray-700);
    box-shadow: unset;
  }

  &--shadowless {
    box-shadow: unset;
  }

  &--loading {
    margin-bottom: -80px;
    --background: transparent;
    --title-color: var(--c-gray-700);
    --toolbar-icons-color: var(--c-primary-pink);
    box-shadow: unset;
  }

  // hide options button when loading
  &--loading ion-buttons.buttons-last-slot {
    display: none;
  }
}

.header-md::after {
  background-image: none;
}
