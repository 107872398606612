.c-card {
  padding: var(--space-regular);
  overflow-y: visible !important;
  background-color: var(--c-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  &--image {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  &__title {
    margin-bottom: 0;
    color: var(--c-black);
    font-size: var(--text-lg);
    font-weight: 500;
    text-align: left;
  }

  &__subtitle {
    color: var(--c-gray-600);
    font-size: var(--text-xs);
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
  }

  &__description {
    color: var(--c-gray-600);
    font-size: var(--text-md);
    line-height: 1.1875rem;
    letter-spacing: 0.15px;
    text-align: left;
  }
}
