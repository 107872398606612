:root {
  ion-toast {
    --background: var(--c-white) !important;
    --border-radius: 100px;
    --box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    --color: var(--c-black);
    --ion-color-step-100: var(--c-black);
    --width: fit-content;
    --max-width: 90%;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }
}

.c-toast {
  &--success {
    --button-color: var(--c-additional-green);
  }

  &--error {
    --button-color: var(--c-additional-red);
  }

  &--info {
    --button-color: var(--c-primary-blue);
  }

  &--alert {
    --button-color: var(--c-primary-orange);
  }

  &--redirect {
    text-align: left !important;
  }
}
