.c-list {
  margin: 0;
  padding: 0;
  list-style: none;

  --border-padding: 4.25rem;
  --border-spacing: var(--space-regular);
  --border-color: var(--c-gray-300);

  &--sm {
    --border-spacing: var(--space-sm);
  }

  &--border-none {
    --border-padding: 0rem;
  }

  &--border-xs {
    --border-padding: 2rem;
  }

  &--border-sm {
    --border-padding: 2.625rem;
  }

  &--reverse {
    --border-color: rgba(33, 33, 33, 0.2);
  }

  &__item {
    &:not(:last-of-type) {
      position: relative;
      margin-bottom: var(--border-spacing);
      padding-bottom: var(--border-spacing);
    }

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: var(--border-padding);
      width: calc(100% - var(--border-padding));
      border-bottom: 1px solid var(--border-color);
    }

    &--summary::-webkit-details-marker {
      display: none;
    }
  }
}
