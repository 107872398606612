.c-radio-group {
  position: relative;
}

.c-radio-item {
  display: flex;

  --background: transparent;
  --background-activated: transparent;
  --border-color: rgba(0, 0, 0, 0.15);
  --inner-border-width: 0px;
  --padding-start: 0px;

  // our css variables
  --label-color: var(--c-black);
  --input-color: var(--c-black);
  --input-color-checked: var(--c-main);
  --border-padding: 1.8rem;
  --border-spacing: var(--space-xxs);

  &__label {
    color: var(--label-color);
    font-size: 17px;
  }

  &__icon {
    margin-right: var(--space-md);
    font-size: var(--text-md);
    line-height: var(--body-line-height);
  }

  &__input {
    border: none;
    margin: 0;
    margin-right: var(--space-sm);
    height: var(--space-regular);
    width: var(--space-regular);

    --color: var(--input-color);
    --color-checked: var(--input-color-checked);
  }

  &:not(:last-of-type) {
    position: relative;
    margin-bottom: var(--border-spacing);
    padding-bottom: var(--border-spacing);

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: var(--border-padding);
      width: calc(100% - var(--border-padding));
      border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    }
  }

  &--inverse {
    --label-color: var(--c-white);
    --input-color: var(--c-white);
    --input-color-checked: var(--c-white);
  }
}
